import article1 from "../images/blogs/article1.png"
import article2 from "../images/blogs/article2.png"
import article3 from "../images/blogs/article3.png"
import article4 from "../images/blogs/article4.png"
import article5 from "../images/blogs/article5.png"
import article6 from "../images/blogs/article6.png"
import article7 from "../images/blogs/article7.png"
import article8 from "../images/blogs/article8.png"
import article9 from "../images/blogs/article9.png"
import article10 from "../images/blogs/article10.png"
import article11 from "../images/blogs/article11.png"
import article12 from "../images/blogs/article12.png"
import article13 from "../images/blogs/article13.png"

export const blogsArr = [
  {
    image: article1,
    type: "Article",
    description:
      "COVID has transformed how businesses run and how leaders lead. The World Economic...",
    date: "20th July 2020",
    title:
      "What COVID-19 taught 10 startups about pivoting, problem solving and tackling the unknown",
    link:
      "https://www.weforum.org/agenda/2020/07/what-covid-19-taught-10-startups-about-pivoting-problem-solving-and-tackling-the-unknown/",
  },
  {
    image: article2,
    type: "Article",
    description:
      "The challenge categorized the animal husbandry ecosystem issues into 6 categories. Mooofarm....",
    date: "9th May 2020",
    title: "MoooFarm wins Start-up India's Animal Husbandry Grand Challenge",
    link:
      "https://aninews.in/news/business/mooofarm-wins-start-up-indias-animal-husbandry-grand-challenge20200509143514/",
  },
  {
    image: article3,
    type: "Article",
    description:
      "India is the top milk producer globally and it is also a country where traditional and modern....",
    date: "3rd June 2020",
    title: "From tradition to technology - empowering India's dairy farmers",
    link:
      "https://www.dairyglobal.net/Smart-farming/Articles/2020/6/The-2-sides-of-Indias-dairy-sector-592553E/",
  },
  {
    image: article4,
    type: "Article",
    description:
      "Karamjeet Kaur attended a MoooFarm training session because her cows were sick with ticks.....",
    date: "10th March 2020",
    title: "75M Indian women work in dairy — MoooFarm wants to help them",
    link:
      "https://www.darigold.com/mooofarm-targets-women-dairy-farmers-in-india/",
  },
  {
    image: article5,
    type: "Article",
    description:
      "An award winning dairy tech start-up, Mooofarm on wednessday announced to provide on-demand.....",
    date: "13th May 2020",
    title: "Online assistance for dairy farmers launched",
    link:
      "https://www.cityairnews.com/content/online-assistance-for-dairy-farmers-launched",
  },
  {
    image: article6,
    type: "Article",
    description:
      "Mooofarm on Monday said it will train two lakh Indian dairy farmers by 2020 to develop their....",
    date: "26th Nov 2018",
    title: "MoooFarm to train 2 lakh farmers in dairy skills",
    link:
      "https://economictimes.indiatimes.com/news/economy/agriculture/mooofarm-to-train-2-lakh-farmers-in-dairy-skills/articleshow/66809038.cms",
  },
  {
    image: article7,
    type: "Article",
    description:
      "Having arrived in Australia as a student, become a lecturer.....",
    date: "19th August 2019",
    title:
      "Meet the entrepreneur striving to help 100 million people by 2030 — partly through facial recognition for cows",
    link:
      "https://www.smartcompany.com.au/startupsmart/news/mooofarm-facial-recognition-cows/",
  },
  {
    image: article8,
    type: "Article",
    description:
      "Have you ever been able to tell one cow apart from another?....",
    date: "13th Sept 2019",
    title:
      "This company uses machine learning to tell cows apart from each other",
    link:
      "https://www.livemint.com/technology/tech-news/this-company-uses-machine-learning-to-tell-cows-apart-from-each-other-1568386227724.html",
  },
  {
    image: article9,
    type: "Article",
    description:
      "Nitin Gadkari, Cabinet Minister for Road Transport & Highways of India, announced the MoU signing ceremony....",
    date: "7th Sept 2019",
    title: "MoU signing ceremony of NDDB and Government of Maharashtra",
    link:
      "https://thelivenagpur.com/2019/09/07/mou-signing-ceremony-of-nddb-and-government-of-maharashtra/",
  },
  {
    image: article10,
    type: "Article",
    description:
      "Gurugram-based startup MoooFarm will be working with Microsoft to help Indian dairy....",
    date: "9th May 2020",
    title:
      "Milktech startup MoooFarm to work with Microsoft to help Indian dairy farmers",
    link:
      "https://yourstory.com/2019/05/startup-milktech-mooofarm-microsoft-dairy-farmers",
  },
  {
    image: article11,
    type: "Article",
    description:
      "Whitetech revolution aims to technologize the dairy farming industry by using big data.....",
    date: "27th Oct 2019",
    title: "Whitetech Revolution The Future Of Dairy-Param Preet Singh",
    link:
      "http://www.businessworld.in/article/Whitetech-Revolution-The-Future-Of-Dairy/27-10-2019-178091/",
  },
  {
    image: article12,
    type: "Article",
    description:
      "With its goal of increasing the livelihoods of 1m Indian dairy farmers by 2022.....",
    date: "9th Jan 2019",
    title: "Mooo App hopes to deliver mooo money to Indian dairy farmers",
    link:
      "https://www.dairyreporter.com/Article/2019/01/09/Mooo-App-hopes-to-deliver-mooo-money-to-Indian-dairy-farmers",
  },
  {
    image: article13,
    type: "Article",
    description:
      "MoooFarm, an Australia-based innovative agri-tech company today launched its mobile app.....",
    date: "10th March 2020",
    title:
      "Mobile application ‘MoooFarm’ launched to cater to small dairy farmers",
    link:
      "https://www.thehindubusinessline.com/economy/agri-business/mobile-application-mooofarm-launched-to-cater-to-small-dairy-farmers/article26195297.ece",
  },
]
