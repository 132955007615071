import React from "react"
import Layout from "../../components/layout"
import Slider from "react-slick"
import { awardArr } from "../../data/awards-details.js"
import { blogsArr } from "../../data/blogs-details.js"
import nextArrow from "../../images/next.svg"
import prevArrow from "../../images/back.svg"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../styles/stories-awards.css"

const StoriesAwardsPage = () => {
  const imageSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: "sa-slider-dots",
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    // nextArrow: <img className="arrow-style" src={nextArrow} alt="arrow" />,
    // prevArrow: <img className="arrow-style" src={prevArrow} alt="arrow" />
  }

  const awardSliderSettings = {
    // dots: true,
    className: "award-center",
    centerMode: true,
    centerPadding: "75px",
    infinite: true,
    slidesToShow: 3,
    dotsClass: "sa-slider-dots",
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    nextArrow: <img className="arrow-style" src={nextArrow} alt="arrow" />,
    prevArrow: <img className="arrow-style" src={prevArrow} alt="arrow" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "10px",
        },
      },
    ],
  }

  const blogsSliderSettings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    dotsClass: "sa-slider-dots",
    // autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    nextArrow: <img className="arrow-style" src={nextArrow} alt="arrow" />,
    prevArrow: <img className="arrow-style" src={prevArrow} alt="arrow" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "10px",
        },
      },
    ],
  }

  return (
    <Layout>
      <div className="sa-container">
        <div className="sa-image-slider">
          <div className="sa-image-slider-wrapper">
            <Slider {...imageSliderSettings}>
              <div className="slider-images slider-background-1">
                <div className="slider-images-content">
                  Winner of World Bank’s Agri-InsureTech Challenge 2019
                </div>
              </div>
              <div className="slider-images slider-background-2">
                <div className="slider-images-content">
                  MoU signing ceremony of MoooFarm, NDDB and Government of
                  Maharashtra
                </div>
              </div>
              <div className="slider-images slider-background-3">
                <div className="slider-images-content">
                  MoooFarm joins World Economic Forum's Global Innovators
                  Community
                </div>
              </div>
              <div className="slider-images slider-background-4">
                <div className="slider-images-content">
                  MoooFarm’s Mobile Application Launch
                </div>
              </div>
              <div className="slider-images slider-background-5">
                <div className="slider-images-content">
                  MoooFarm selected amongst the six Startups for Netherland’s
                  Rockstart AgriFood program 2020
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="sa-awards-container">
          <div className="container-heading-wrapper left-align">
            <div className="container-heading-line"></div>
            <div className="container-heading">
              Our <span className="heading-bold-text">Awards</span>
            </div>
          </div>
          <div className="sa-awards-images-container">
            <Slider {...awardSliderSettings}>
              {awardArr &&
                awardArr.map((award, index) => (
                  <div key={index} className="award-indi-div">
                    <div className={`award-indi-div-wrapper`}>
                      <div
                        className="award-indi-image"
                        style={{
                          backgroundImage: `url(${award.image})`,
                        }}
                      />
                      <div className="award-indi-content">
                        {award.description}
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
        <div className="sa-blogs-container">
          <div className="container-heading-wrapper left-align">
            <div className="container-heading-line"></div>
            <div className="container-heading">
              Our <span className="heading-bold-text">Blogs & Articles</span>
            </div>
          </div>
          <div className="sa-blogs-images-container">
            <Slider {...blogsSliderSettings}>
              {blogsArr &&
                blogsArr.map((blog, index) => (
                  <div key={index} className="blog-indi-div">
                    <a target="_blank" href={blog.link}>
                      <div className="blog-indi-div-wrapper">
                        <div
                          className="blog-indi-image"
                          style={{
                            backgroundImage: `url(${blog.image})`,
                          }}
                        />
                        <div className="blog-indi-type">
                          <div className="bit-type">{blog.type}</div>
                          <div className="bit-date">{blog.date}</div>
                        </div>
                        <div className="blog-indi-content">
                          <div className="blog-indi-content-heading">
                            {blog.title}
                          </div>
                          <div className="blog-indi-content-desc">
                            {blog.description}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default StoriesAwardsPage
