import award1Img from "../images/awards/a1.png"
import award2Img from "../images/awards/a2.png"
import award3Img from "../images/awards/a3.png"
import award4Img from "../images/awards/a4.png"
import award5Img from "../images/awards/a5.png"

export const awardArr = [
  {
    image: award1Img,
    description:
      "Most Innovative Idea of the Year by TieCon & Start-Up Punjab 2018",
  },
  {
    image: award2Img,
    description:
      "Winner of World Bank’s Agri-InsureTech Challenge 2019 for developing facial recognition of cattle.",
  },
  {
    image: award3Img,
    description:
      "Microsoft Artificial Intelligence for Earth Grant 2019 for Mastitis Detection using Machine Learning.",
  },
  {
    image: award4Img,
    description:
      "Winner of National Animal Husbandry Startup Grand Challenge by Govt of India and Start-Up India 2020.",
  },
  {
    image: award5Img,
    description: "Top 100 Social Entrepreneurs by SoCap, USA 2018.",
  },
]
